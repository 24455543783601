import React from 'react'
import { SectionContent } from '../../../types/sections'
import { Flex, Box, Heading, Text, Button } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'

function Content(props: SectionContent): JSX.Element {
  const { id, sectionId, headline, content, whiteContent, buttons, backgroundColor } = props
  return (
    <Box
      as={'section'}
      id={sectionId ?? id}
      backgroundColor={backgroundColor?.hex ?? 'dark'}
      my={SECTION_MARGIN}
      py={SECTION_MARGIN}
    >
      <Grid>
        <Row center={'xs'}>
          <Col xs={12} sm={10} md={5}>
            <Heading as={'h2'} type={'heading1'} color={whiteContent ? 'white' : 'dark'}>
              {headline}
            </Heading>
          </Col>
          <Col xs={12} sm={10} md={6}>
            <Box pl={[0, 0, 6]} mt={[6, 6, 0]}>
              <Text type={'large'} color={whiteContent ? 'white' : 'dark'}>
                {content}
              </Text>

              {buttons.length > 0 && (
                <Flex mt={6} alignItems={'center'} flexWrap={'wrap'} rowGap={4}>
                  {buttons.map((button) => (
                    <Box key={button.id}>
                      <Button key={button.id} {...button} />
                    </Box>
                  ))}
                </Flex>
              )}
            </Box>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default Content
